let GMap, GEvent;
if (process.browser) {
	GMap = window.goongjs;
	// GEvent = window.google.event;
}
// BX Thai Nguyen 21.57635,105.82901
export default {
	namespaced: true,
	state: () => ({
		map: null,
		latitude: 21.57635,
		longitude: 105.82901,
		latlongtude: [105.82901, 21.57635],
		lngLatCenter: [105.82901, 21.57635], // Lưu lại tọa độ trung tâm điểm đến đang tìm kiếm
		latCenter: 1.5, // Trừ đi giá trị này để đặt trung tâm bản đồ khi popup ds và chi tiết chuyến đi hiển thị
		popups: [],
		markers: [],
		idLayers: [],
		boundsDiemDung: null,// zoom vao diem dung dang cos
		zoom: 8,
		data: {
			toaDoDiemDungDangChon: null
		},
		old: {//Lưu lại để không load lại dữ liệu đã tìm kiếm trước đó
			loTrinh: [],//MaTuyen
		},
		maLoTrinhDangChon: "",
		thucHienVeLoTrinh: 0,
	}),
	getters: {
		map: state => state.map,
		getPopups: state => state.popups,
		getMarkers: state => state.markers,
		getThucHienVeLoTrinh: state => state.thucHienVeLoTrinh,
		getMaLoTrinhDangChon: state => state.maLoTrinhDangChon,
		getLngLatCenter: state => state.lngLatCenter,
		getLatCenter: state => state.latCenter,
		getBoundsDiemDung: state => state.boundsDiemDung,
		getLatlongtude: state => state.latlongtude,
		getIdLayers: state => state.idLayers,
		//old
		getOldLoTrinh: state => state.old.loTrinh,
	},
	mutations: {
		set(state, data) {
			state[data.key] = data.value;
		},
		setData(state, data) {
			state["data"][data.key] = data.value;
		},
		setOld(state, { key, value }) {
			state["old"][key] = value;
		},
		setOldLoTrinh(state, value) {
			state.old.loTrinh.push(value);
		},
		setPopups(state, value) {
			state.popups.push(value);
		},
		setMarkers(state, value) {
			state.markers.push(value);
		},
		setMap(state, value) {
			state.map = value;
		},
		setThucHienVeLoTrinh(state) {
			state.getThucHienVeLoTrinh++;
		},
		setMaLoTrinhDangChon(state, value) {
			state.maLoTrinhDangChon = value;
		},
		setLatlongtude(state, value) {
			state.latlongtude = value;
		},
		setIdLayers(state, value) {
			state.idLayers.push(value);
		}
	},

	actions: {
		initMap({ state, commit }) {
			if (!GMap) {
				throw new Error("Đã xảy ra lỗi Gmap")
			}
			GMap.accessToken = window.SP["KeyMap"] || 'J9xMDYwEzBI9GZaHFMYVGWlQBEswFBKIP7qyCjr6';
			let map = new GMap.Map({
				container: "map",
				style: "https://tiles.goong.io/assets/goong_light_v2.json",
				center: [20.07635, 105.82901].reverse(),
				zoom: state.zoom,
				pitch: 10,
				logoPosition: "bottom-left",
			});
			// touchZoomRotate: false
			commit('setMap', map)
		},
		drawMarker({ state, commit }, latlongtude) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			if (!latlongtude) {
				latlongtude = state.latlongtude
			}
			let marker = new GMap.Marker().setLngLat(latlongtude)
				.addTo(state.map);
			commit("setMarkers", marker)
			return marker;
		},
		drawPopoverDiemDen({ state, commit }, { lngLat }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			var markerHeight = 50, markerRadius = 10, linearOffset = 25;
			var popupOffsets = {
				'top': [0, 0],
				'top-left': [0, 0],
				'top-right': [0, 0],
				'bottom': [0, -markerHeight],
				'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
				'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
				'left': [markerRadius, (markerHeight - markerRadius) * -1],
				'right': [-markerRadius, (markerHeight - markerRadius) * -1]
			};
			var popup = new GMap.Popup({ offset: popupOffsets, className: 'popover-diemden' })
				.setLngLat(lngLat)
				.setHTML("<p>Bến xe Thái Nguyên!</p>")
				.setMaxWidth("300px")
				.addTo(state.map);
			return popup;
		},
		drawPopoverLoTrinh({ rootGetters, state, commit, dispatch }, { lngLat, content, idLayer }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			let html = `
			<div class="font-16 white--text">${content.TenLoTrinh}</div>
			`
			var popup = new GMap.Popup({ anchor: "bottom", closeButton: false, closeOnClick: false, className: 'popover-lotrinh' })
				.setLngLat(lngLat)
				.setHTML(html)
				.setMaxWidth("300px")
				.addTo(state.map);
			// Object.defineProperties(popup,)
			commit("setPopups", popup)
			var popupElem = popup.getElement();
			popupElem.setAttribute("matuyen", content.MaTuyen);
			popupElem.addEventListener("click", function (e) {
				dispatch("toMauChoLoTrinh", { idLayer: idLayer, maLoTrinh: content.MaTuyen })
				//Hiển thị ds chuyến đi
				if (!rootGetters["timKiemChuyenDi/getDialogDsChuyenDi"]) {
					commit("timKiemChuyenDi/setDialogDsChuyenDi", true, { root: true })
				}
			})
			return { popup };
		},
		async loadGeoJson({ }, path) {
			if (!path) {
				throw new Error("Không có googleMapObject")
			}
			let rs = await fetch(path, {
				method: "GET",
				mode: "cors",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
			});
			return rs.json();
		},
		kiemTraLoTrinhDaVe({ state }, maTuyen) {
			return state.old.loTrinh.some(s => s == maTuyen)
		},
		async veLoTrinh({ rootGetters, state, dispatch, commit }, { geoJson, idSource = "lo-trinh", idLayer = 0 }) {
			commit("set", { key: "setIdLayers", value: [] })
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			if (!geoJson) {
				throw new Error("Không có GeoJSON")
			}
			let cDuong = geoJson.features[0].geometry.coordinates;
			let dloTrinh = geoJson.features[0].properties.DataCustom;
			//Không vẽ lại lộ trình
			let check = await dispatch("kiemTraLoTrinhDaVe", dloTrinh.MaTuyen)
			if (check) {
				return
			}
			state.map.addSource(idSource, {
				'type': 'geojson',
				'data': geoJson
			});
			state.map.addLayer({
				'id': "lotrinh-duong" + idLayer,
				'type': 'line',
				'source': idSource,
				'paint': {
					'line-width': 10,
					'line-color': '#FF8882'//#8AC6FE
				},
				'filter': ['==', '$type', 'LineString']
			});
			state.map.addLayer({
				'id': "lotrinh-diem" + idLayer,
				'type': 'circle',
				'source': idSource,
				'paint': {
					'circle-radius': 6,
					'circle-color': '#B42222'
				},
				'filter': ['==', '$type', 'Point']
			});
			//Lưu lại ds layerlotrinh-duong
			commit("setIdLayers", idLayer)
			let diemGiua = parseInt(cDuong.length / 2);
			let toaDoPopover = cDuong[diemGiua];
			commit("setOldLoTrinh", dloTrinh.MaTuyen)
			await dispatch("drawPopoverLoTrinh", { lngLat: toaDoPopover, content: dloTrinh, idLayer: idLayer })
			//Click lo trinh
			state.map.on("click", 'lotrinh-duong' + idLayer, function () {
				dispatch("toMauChoLoTrinh", { idLayer: idLayer, maLoTrinh: dloTrinh.MaTuyen })
				//Hiển thị ds chuyến đi
				if (!rootGetters["timKiemChuyenDi/getDialogDsChuyenDi"]) {
					commit("timKiemChuyenDi/setDialogDsChuyenDi", true, { root: true })
				}
			});
		},
		async veDiemDung({ state, dispatch, commit }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			let diemDung = await dispatch("layDiemDung")
			if (!diemDung) {
				return
			}
			if (diemDung.type == "MultiPolygon") {
				if (
					Array.isArray(diemDung.coordinates) &&
					diemDung.coordinates.length > 0
				) {
					let geometrys = []
					let coordinatesAll = []
					diemDung.coordinates.forEach(async (e) => {
						let coordinates = [];
						(function toSingleArray(data) {
							data.forEach((f) => {
								if (Array.isArray(f) && f.length > 0) {
									toSingleArray(f);
								} else {
									coordinates.push([f.lng, f.lat]);
									coordinatesAll.push([f.lng, f.lat])
								}
							});
						})(e);
						//ve
						dispatch("xoaDiemDung")
						geometrys.push({
							'type': 'Feature',
							'geometry': {
								'type': 'Polygon',
								'coordinates': [
									coordinates
								]
							}
						})
					});
					state.map.addSource('diemdung', {
						'type': 'geojson',
						'data': {
							'type': 'FeatureCollection',
							'features': geometrys
						}
					});
					state.map.addLayer({
						'id': 'diemdung-polygon',
						'type': 'fill',
						'source': 'diemdung',
						'paint': {
							'fill-outline-color': '#FF0000',
							'fill-color': '#000000',
							'fill-opacity': 0.2
						},
					});
					const bounds = new GMap.LngLatBounds(
						coordinatesAll[0],
						coordinatesAll[0]
					);
					let boundsCenter = bounds.getCenter()
					for (const coord of coordinatesAll) {
						bounds.extend(coord);
					}
					dispatch("fitBounds", { bounds: bounds })
					commit("set", { key: "boundsDiemDung", value: bounds })
					commit("set", { key: "lngLatCenter", value: [boundsCenter.lng, boundsCenter.lat] })

				}
			}
			else if (diemDung.type === "Point") {
				//Hiển thị vị trí điểm đến đã chọn
				dispatch("drawMarker", diemDung.point)
				state.map.panTo([diemDung.point.lng, diemDung.point.lat - state.latCenter]);
			}
		},
		timKiemChuyenDiTheoViTri({ state, dispatch, commit }) {
			try {
				commit("timKiemChuyenDi/setModel", { key: "diemDenDangChon", value: null }, { root: true })
				dispatch("xoaMarkers")
				if (state.latlongtude.length == 0) {
					throw new Error("state.latlongtude rỗng")
				}
				commit("timKiemChuyenDi/setTimKiem", `Vị trí được ghim tại (${state.latlongtude[0].toFixed(2)}, ${state.latlongtude[1].toFixed(2)})`, { root: true })
				dispatch("drawMarker")
			} catch (error) {
				throw new Error(error)
			}
		},
		async layThongTinViTriDangChon({ state, commit }) {
			try {
				if (!state.latlongtude && state.latlongtude.length == 0) {
					throw new Error("Không có latlongtude")
				}
				// commit("timKiemChuyenDi/setModel", {
				// 	key: "diemDenDangChon",
				// 	value: null,
				// }, { root: true });
				let query = {
					toaDo: state.latlongtude,
					doSau: "CAP_XA",
				}
				this.$loading.show();
				let resp = await $Core.Api.Map(this.$i18n.global.t("url.LayThongTinViTri"), query).Get();
				this.$loading.hide();
				if (resp.StatusCode === 200) {
					let data = resp.Data.data;
					commit("timKiemChuyenDi/setTimKiem", data ? data.TenViTri : "", { root: true })
					commit("timKiemChuyenDi/setModel", {
						key: "diemDenDangChon",
						value: {
							placemarkId: data ? data.PlacemarkId : "",
							loaiViTri: null,
							tenViTri: data ? data.TenViTri : "",
						},
					}, { root: true })
					return data;
				}
			} catch (error) {
				this.$loading.hide();
				throw new Error(error)
			}
		},
		toMauChoLoTrinh({ state, dispatch, commit }, { idLayer, maLoTrinh }) {
			try {
				if (idLayer < 0) {
					throw new Error("Không có idLayer")
				}
				if (!maLoTrinh) {
					throw new Error("Không có maLoTrinh")
				}
				state.map.moveLayer("lotrinh-duong" + idLayer)
				state.map.setPaintProperty("lotrinh-duong" + idLayer, "line-color", "#e55b21")//#1890FF
				for (let i = 0; i < state.idLayers.length; i++) {
					if (idLayer != i) {
						state.map.setPaintProperty("lotrinh-duong" + i, "line-color", "#FF8882")
						//Lấy mã lộ trình để tìm kiếm chuyến đi theo lộ trình đang chọn
						dispatch("timKiemChuyenDi/layDanhSachChuyenDi", { maLoTrinh: maLoTrinh }, { root: true })
						commit("setMaLoTrinhDangChon", maLoTrinh)
					}
				}
			} catch (error) {
				throw new Error(error)
			}
		},
		xoaLoTrinh({ state, dispatch }, { idSource = "lo-trinh", idLayer = 0 }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			dispatch("xoaPopup");
			if (state.map.getLayer("lotrinh-duong" + idLayer)) {
				state.map.removeLayer("lotrinh-duong" + idLayer);
			}
			if (state.map.getLayer("lotrinh-diem" + idLayer)) {
				state.map.removeLayer("lotrinh-diem" + idLayer);
			}
			if (state.map.getSource(idSource)) {
				state.map.removeSource(idSource);
			}
		},
		xoaDsLoTrinh({ state, dispatch, commit }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			dispatch("xoaPopup");
			for (let i = 0; i < state.idLayers.length; i++) {
				let e = state.idLayers[i];
				let a = state.map.getSource(`lo-trinh${e}`);
				if (state.map.getLayer(`lotrinh-duong${e}`)) {
					state.map.removeLayer(`lotrinh-duong${e}`);
				}
				if (state.map.getLayer(`lotrinh-diem${e}`)) {
					state.map.removeLayer(`lotrinh-diem${e}`);
				}
				if (state.map.getSource(`lo-trinh${e}`)) {
					state.map.removeSource(`lo-trinh${e}`);
				}
			}
			commit("set", { key: "idLayers", value: [] })
		},
		xoaDiemDung({ state }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			if (state.map.getLayer("diemdung-polygon")) {
				state.map.removeLayer("diemdung-polygon");
			}
			if (state.map.getSource("diemdung")) {
				state.map.removeSource("diemdung");
			}
		},
		xoaPopup({ state }) {
			if (state.popups.length > 0) {
				state.popups.forEach(p => {
					p.remove();
				});
			}
		},
		xoaMarkers({ state }) {
			if (state.markers.length > 0) {
				state.markers.forEach((p, i) => {
					if (i > 0) {//Không xóa điểm đi
						p.remove();
					}
				});
			}
		},
		resetNorth({ state, dispatch }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			state.map.resetNorth();
			state.map.resetNorthPitch();
			setTimeout(() => {
				if (state.boundsDiemDung) {
					dispatch("fitBounds", { bounds: state.boundsDiemDung })
				} else {
					state.map.flyTo({
						center: state.lngLatCenter || state.latlongtude,
						zoom: state.zoom
					})
				}
			}, 1000);
		},
		async layDiemDung({ rootGetters, commit, dispatch }) {
			let diemDenDangChon = rootGetters["timKiemChuyenDi/getDiemDenDangChon"]
			let oldDiemDung = rootGetters["timKiemChuyenDi/getOldDiemDung"]
			if (!diemDenDangChon) {
				return
			}
			//Không lấy lại điểm đến đã có
			if (oldDiemDung == diemDenDangChon.placemarkId) {
				return null
			}
			dispatch("xoaDiemDung");
			this.$loading.show();
			let rs = await this.$Core.Api.Make(
				this.$i18n.global.t("url.LayThongTinDiemDungTrenBanDoSo"),
				{ guidDiemDung: diemDenDangChon.placemarkId }
			).Get();
			this.$loading.hide();
			if (rs.StatusCode === 200) {
				let data = rs.Data.data;
				commit("timKiemChuyenDi/setOld", { key: "diemDung", value: diemDenDangChon.placemarkId }, { root: true })
				return data;
			}
		},
		fitBounds({ state }, { bounds }) {
			if (!state.map) {
				throw new Error("Chưa khởi tạo map")
			}
			state.map.fitBounds(bounds, {
				padding: 450,
				offset: [0, -200],
				linear: false,
			});
		},
		getCenterPolygon({ commit }, coordinates) {
			if (!Array.isArray(coordinates)) {
				return [];
			}
			if (coordinates.length == 0) {
				return [];
			}
			let x = coordinates.map(c => c[0])
			let y = coordinates.map(c => c[1])

			let minX = Math.min.apply(null, x)
			let maxX = Math.max.apply(null, x)

			let minY = Math.min.apply(null, y)
			let maxY = Math.max.apply(null, y)
			let center = [
				(minX + maxX) / 2,
				(minY + maxY) / 2
			]
			commit("set", { key: "lngLatCenter", value: center })
			return center
		},
		clearMap({ state, dispatch, commit }) {
			if (!state.map) {
				return
			}
			dispatch("xoaDsLoTrinh")
			dispatch("xoaDiemDung")
			dispatch("xoaPopup")
			dispatch("xoaMarkers")
			commit("set", { key: "idLayers", value: [] })
			commit("set", { key: "popups", value: [] })
			commit("set", { key: "markers", value: [] })
			commit("set", { key: "latlongtude", value: [105.82901, 21.57635] })
			commit("set", { key: "lngLatCenter", value: [105.82901, 21.57635] })
			commit("set", { key: "boundsDiemDung", value: null })
			state.map.remove();
		}
	}
};