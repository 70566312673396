import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/home";
// import defaultLayout from "./layouts/side-nav-inner-toolbar";
// import menuApplication from "./layouts/menu-application";
import application from "./layouts/application";
import defaults from "./layouts/default";
import none from "./layouts/none";
// import simpleLayout from "./layouts/single-card";

function loadView(view) {
    return () => import(`./views/${view}.vue`);
}
// function loadViewIndex(view) {
//     return () => import(`./views/${view}/index.vue`);
// }

var my_router_options = {
    routes: [
        {
            path: "/:pathMatch(.*)*",
            redirect: "/"
        }
    ],
    history: createWebHistory(process.env.BASE_URL),
};

function routing(viewName, title, requiresAuth, path, routeName, layout) {
    layout = layout || none;
    requiresAuth = !!(requiresAuth);
    routeName = routeName || viewName;
    path = path || `/${viewName}`;

    let route = {
        path: path,
        name: routeName,
        meta: {
            requiresAuth: requiresAuth,
            layout: layout,
            title: title
        },
        component: loadView(viewName)
    };
    my_router_options.routes.push(route);
    return route;
}

routing("home", "Sbus.vn", true, "/");
routing("dang-nhap", "Sbus.vn", false);
routing("ecosystem", "Sbus.vn", false);
routing("tim-kiem-chuyen-di", "Sbus.vn", true);
routing("chon-vi-tri-cho-ngoi", "Sbus.vn", true);
routing("thong-tin-thanh-toan", "Sbus.vn", true);
routing("in-ve", "Sbus.vn", true);

export default function ($Core) {
    const router = createRouter(my_router_options);

    router.beforeEach((to, from, next) => {
        if (to.name === "dang-nhap" && $Core.AuthApi.IsAuth) {
            next({ name: "home" });
        }
        if (to.meta.requiresAuth) {
            if (!$Core.AuthApi.IsAuth) {
                next({ name: "dang-nhap" });
                $Core.AuthApi.Login();
            } else {
                next();
            }
        } else {
            next();
        }
    });
    return router;
}
